import { SortDirection } from '@angular/material/sort';
import { environment } from '@env/environment';
import { NumberUnitModel } from '@mod/common/number-unit.model';
import { IHeatmapFilters } from '@mod/data/sp-500.model';
import { SymbolSearchSettingsRecord } from '@mod/user-settings/symbol-search.model';
import { TradingLogTransactionType } from '@t/trading-log';
import { VideoHubApiKeysType } from '@t/video-hub/video-hub-api-keys-type.enum';
import { VideoHubWordPressUrlsType } from '@t/video-hub/video-hub-wordpress-urls-type.enum';
import * as moment from 'moment';

export const ChartHistoryIntervalMonths = 5 * 12; // display 5 years of historical data
export const DividendsStrategyChartHistoryIntervalMonths = 5 * 12 + 1; // display 5 years + 1 month of historical data
export const WtfChartHistoryIntervalMonths = 6 * 12; // display 6 years of historical data

export const SHORT_SELLING_CHART_HISTORY_INTERVAL_MONTHS = 1; // display 3 months of historical data

export const UserSettings = {
  IsDeveloperAccount: 'isDeveloperAccount',
  ActiveTab: 'activeTab',
  LoginNavigationSettings: 'loginNavigationSettings',
  TradePosition: 'tradePosition',
  ChartTab: 'chartTab',
  FirstTradingStrategyId: 'firstTradingStrategyId',
  SecondTradingStrategyId: 'secondTradingStrategyId',
  RunScannerTradingStrategyId: 'runScannerTradingStrategyId',
  TradeReportTradingStrategyId: 'tradeReportTradingStrategyId',
  ShowEntryAndExitOption: 'showEntryAndExitOption',
  ShowTradeOption: 'showTradeOption',
  ShowTechnicalIndicators: 'showTechnicalIndicators',
  Symbol: 'symbol',
  AccountSize: 'accountSize',
  AccountRiskPercent: 'accountRiskPercent',
  AccountRiskAmount: 'accountRiskAmount',
  ScannerResultsSettings: 'scannerResultsSettings',
  JoinCommunityBtnVisible: 'joinCommunityBtnVisible',
  WatchPxoTutorialsBtnVisible: 'watchPxoTutorialsBtnVisible',
  SelectScannerTab: 'selectScannerTab',
  WatchlistSort: 'watchlistSort',
  ScannerResultSort: 'scannerResultSort',
  WheelSymbol: 'wheelSymbol',
  IsWheelCalculator: 'isWheelCalculator',
  IsWheelPremiums: 'isWheelPremiums',
  PrintOptions: 'printOptions',
  IsWheelFiltersHidden: 'isWheelFiltersHidden',
  WheelFiltersState: 'wheelFiltersState',
  WheelFiltersPresets: 'wheelFiltersPresets',
  SelectedWheelFiltersPresetId: 'selectedWheelFiltersPresetId',
  WheelTopTab: 'wheelTopTab',
  WheelScannerTab: 'wheelScannerTab',
  WheelScannerSortAggressive: 'wheelScannerSortAggressive',
  WheelScannerSortConservative: 'wheelScannerSortConservative',
  WheelScannerSortAll: 'wheelScannerSortAll',
  WheelWatchlistSort: 'wheelWatchlistSort',
  HolidaysCountry: 'holidaysCountry',
  AssetCorrelationResentComparationSymbols: 'assetCorrelationResentComparationSymbols',
  AssetCorrelationSelectedSymbols: 'assetCorrelationSelectedSymbols',
  AssetCorrelationSelectedPeriod: 'assetCorrelationSelectedPeriod',
  TradingLogContainerTab: 'tradingLogContainerTab',
  TradingLogTradesTab: 'tradingLogTradesTab',
  TradingLogTradesFilterValue: 'tradingLogTradesFilterValue',
  TradingLogTradesSortingState: 'tradingLogTradesSortingState',
  TradingLogFirstTimeGroupAdded: 'tradingLogFirstTimeGroupAdded',
  TradingLogTradesInfoColumnsStateValue: 'tradingLogTradesInfoColumnsStateValue',
  TradingLogTradesInfoColumnsShowDevFieldsValue: 'tradingLogTradesInfoColumnsShowDevFieldsValue',
  TradingLogTradesShowTimeColumn: 'tradingLogTradesShowTimeColumn',
  TradingLogDashboardSymbolFilterValue: 'tradingLogDashboardSymbolFilterValue',
  TradingLogDashboardDatesFilterValue: 'tradingLogDashboardDatesFilterValue',
  TradingLogDashboardAccountsFilterValues: 'tradingLogDashboardAccountsFilterValues',
  TradingLogDashboardStrategiesFilterValues: 'tradingLogDashboardStrategiesFilterValues',
  TradingLogDashboardOpenPositionsExpandedValues: 'tradingLogDashboardOpenPositionsExpandedValue',
  TradingLogDashboardTab: 'tradingLogDashboardTab',
  TradingLogDashboardWeekdayTradesTab: 'tradingLogDashboardWeekdayTradesTab',
  TradingLogDashboardOptionsSymbolsTableSortState: 'tradingLogDashboardOptionsSymbolsTableSortState',
  TradingLogDashboardStocksSymbolsTableSortState: 'tradingLogDashboardStocksSymbolsTableSortState',
  TradingLogDashboardMetricDetailsModalPageSize: 'tradingLogDashboardMetricDetailsModalPageSize',
  TradingLogDashboardOverallModeState: 'tradingLogDashboardOverallModeState',
  TradingLogSymbolSummaryPanelWheelState: 'tradingLogSymbolSummaryPanelWheelState',
  TradingLogSymbolSummaryPanelWtfState: 'tradingLogSymbolSummaryPanelWtfState',
  TradingLogSymbolSummaryPanelDividendsState: 'tradingLogSymbolSummaryPanelDividendsState',
  TradingLogSelectedInputDateFormat: 'tradingLogSelectedInputDateFormat',

  ImportPageTradesListFilterValue: 'importPageTradesListFilterValue',
  ImportPageTradesListSortValue: 'importPageTradesListSortValue',

  WheelFiltersWidth: 'wheelFiltersWidth',
  WheelFiltersHeight: 'wheelFiltersHeight',
  WheelDataWindowUpdateSource: 'wheelDataWindowUpdateSource',
  WheelViewMode: 'wheelViewMode',
  WheelChartSectionHeight: 'wheelChartSectionHeight',
  WheelROILowerBound: 'wheelROILowerBound',
  WheelROIUpperBound: 'wheelROIUpperBound',
  EarningsCalendarSymbol: 'earningsCalendarSymbol',
  EarningsCalendarTab: 'earningsCalendarTab',
  EarningsFilterValue: 'earningsFilterValue',
  BullCallSpreadSymbol: 'bullCallSpreadSymbol',
  BullCallSpreadRecentSymbols: 'bullCallSpreadSymbols',
  BullCallSpreadTab: 'bullCallSpreadTab',
  BullCallSpreadIsFiltersRolledUp: 'bullCallSpreadIsFiltersRolledUp',
  BullCallSpreadSelectedDropPercentOptions: 'bullCallSpreadSelectedDropPercentOptions',
  BullCallSpreadDetailsSectionWidth: 'bullCallSpreadDetailsSectionWidth',
  TradingHubTab: 'tradingHubTab',
  TradingHubLastOpenDate: 'tradingHubLastOpenDate',
  TradingHubQuotesExpandedState: 'tradingHubQuotesExpandedState',
  TradingHubQuotesLastExpandedDate: 'tradingHubQuotesLastExpandedDate',
  PreTradingChecklistTasks: 'preTradingChecklistTasks',
  PreTradingChecklistOpenOnAppStart: 'preTradingChecklistOpenOnAppStart',
  LowestHighestCloseLineIndicatorWheel: 'lowestHighestCloseLineIndicatorWheel',
  LowestCloseLineIndicatorWheel: 'lowestCloseLineIndicatorWheel',
  LowestHighestCloseLineIndicatorBullCallSpread: 'lowestHighestCloseLineIndicatorBullCallSpread',
  LowestCloseLineIndicatorBullCallSpread: 'lowestCloseLineIndicatorBullCallSpread',
  LowestHighestCloseLineIndicatorStockScreener: 'lowestHighestCloseLineIndicatorStockScreener',
  LowestCloseLineIndicatorStockScreener: 'lowestCloseLineIndicatorStockScreener',
  StockScreenerFiltersPresets: 'stockScreenerFiltersPresets',
  SelectedStockScreenerFiltersPresetId: 'selectedStockScreenerFiltersPresetId',
  TradingHubPlans: 'tradingHubPlans',
  TradingHubPlansTab: 'tradingHubPlansTab',
  ShowExpectedMoveOneOnWheel: 'showExpectedMoveOneOnWheel',
  ShowExpectedMoveTwoOnWheel: 'showExpectedMoveTwoOnWheel',
  ValuesInPercentExpectedMove: 'valuesInPercentExpectedMove',
  ShowPowerXStrategyIndicatorWheel: 'showPowerXStrategyIndicatorWheel',
  ShowPowerXStrategyIndicatorBullCallSpread: 'showPowerXStrategyIndicatorBullCallSpread',
  ShowPowerXStrategyIndicatorStockScreener: 'showPowerXStrategyIndicatorStockScreener',
  TradierSelectedAccount: 'tradierSelectedAccount',
  TradingPanelSelectedTab: 'tradingPanelSelectedTab',
  TradingPanelOrdersTabStatusFilter: 'tradingPanelOrdersTabStatusFilter',
  TradingPanelOrdersHistoryTabStatusFilter: 'tradingPanelOrdersHistoryTabStatusFilter',
  TradingPanelPositionsTabSorting: 'tradingPanelPositionsTabSorting',
  TradingPanelOrdersTabSorting: 'tradingPanelOrdersTabSorting',
  TradingPanelOrdersHistoryTabSorting: 'tradingPanelOrdersHistoryTabSorting',
  TradingPanelOrderModalPagePosition: 'tradingPanelOrderModalPagePosition',
  TradingPanelOrderModalPinned: 'tradingPanelOrderModalPinned',
  TradingPanelImportTradesListFilterValue: 'tradingPanelImportTradesListFilterValue',
  TradingPanelImportTradesListSortValue: 'tradingPanelImportTradesListSortValue',

  ShowVolumeIndicatorOnStartForPowerX: 'showVolumeIndicatorOnStartForPowerX',
  ShowVolumeIndicatorOnStartForWheel: 'showVolumeIndicatorOnStartForWheel',
  ShowVolumeIndicatorOnStartForBCS: 'showVolumeIndicatorOnStartForBCS',
  ShowVolumeIndicatorOnStartForDividendsStrategy: 'showVolumeIndicatorOnStartForDividendsStrategy',
  ShowVolumeIndicatorOnStartForStockScreener: 'showVolumeIndicatorOnStartForStockScreener',
  PlayOpenMarketSound: 'playOpenMarketSound',
  PlayCloseMarketSound: 'playCloseMarketSound',
  ShowSmileyStatistics: 'showSmileyStatistics',
  AccessLevel: 'accessLevel',
  IncomeStatementIsExpanded: 'incomeStatementIsExpanded',
  IncomeStatementTab: 'incomeStatementTab',
  QuickLinks: 'quickLinks',
  WtfSymbol: 'wtfSymbol',
  WtfChartTab: 'wtfChartTab',
  WtfShowTradesOnChart: 'wtfShowTradesOnChart',
  WtfAccountSize: 'wtfAccountSize',
  WtfNumberOfPositions: 'wtfNumberOfPositions',
  WtfScannerTab: 'wtfScannerTab',
  WtfScannerFilters: 'wtfScannerFilters',
  IsWtfFiltersHidden: 'isWtfFiltersHidden',
  WtfScannerNewTableSort: 'wtfScannerNewTableSort',
  WtfScannerOpenTableSort: 'wtfScannerOpenTableSort',
  WtfScannerOpenBelowEntryTableSort: 'wtfScannerOpenBelowEntryTableSort',
  WtfWatchlistTableSort: 'wtfWatchlistTableSort',
  WtfDataWindowHeightPercent: 'wtfDataWindowHeightPercent',
  HasEconomicPulseAccess: 'hasEconomicPulseAccess',
  WtfShowEntryStopIndicators: 'wtfShowEntryStopIndicators',
  ShowExpectedMoveOneOnWtf: 'showExpectedMoveOneOnWtf',
  ShowExpectedMoveTwoOnWtf: 'showExpectedMoveTwoOnWtf',
  ValuesInPercentExpectedMoveKeyOnWtf: 'valuesInPercentExpectedMoveKeyOnWtf',
  WtfShowProfitLoss: 'wtfShowProfitLoss',

  DividendsDataWindowHeightPercent: 'dividendsDataWindowHeightPercent',
  DividendsDataTableHeightPercent: 'dividendsDataTableHeightPercent',
  StockScreenerSymbol: 'stockScreenerSymbol',
  StockScreenerDetailsPanelWidth: 'stockScreenerDetailsPanelWidth',
  StockScreenerSymbolDetailsPanelHeight: 'stockScreenerSymbolDetailsPanelHeight',
  StockScreenerTableSizes: 'stockScreenerTableSizes',
  StockScreenerSelectedTableSources: 'stockScreenerSelectTableSources',
  StockScreenerChartTab: 'stockScreenerChartTab',
  StockScreenerDetailsTab: 'stockScreenerDetailsTab',
  IsStockScreenerFiltersHidden: 'isStockScreenerFiltersHidden',
  StockScreenerFilters: 'stockScreenerFilters',
  ShowExpectedMoveOneOnStockScreener: 'showExpectedMoveOneOnStockScreener',
  ShowExpectedMoveTwoOnStockScreener: 'showExpectedMoveTwoOnStockScreener',
  ValuesInPercentExpectedMoveKeyOnStockScreener: 'valuesInPercentExpectedMoveKeyOnStockScreener',

  DividendsStrategyChartTab: 'dividendsStrategyChartTab',
  DividendsStrategySymbol: 'dividendsStrategySymbol',
  DividendsStrategyScannerFilters: 'dividendsStrategyScannerFilters',
  DividendsStrategyFiltersPresets: 'dividendsStrategyFiltersPresets',
  DividendsStrategySelectedFiltersPresetId: 'dividendsStrategySelectedFiltersPresetId',
  DividendsStrategyScannerTab: 'dividendsStrategyScannerTab',
  DividendsStrategyScannerNewTableSort: 'dividendsStrategyScannerNewTableSort',
  DividendsStrategyScannerOpenTableSort: 'dividendsStrategyScannerOpenTableSort',
  DividendsStrategyScannerOpenBelowDivDropTableSort: 'dividendsStrategyScannerOpenBelowDivDropTableSort',
  DividendsStrategyScannerExitsTableSort: 'dividendsStrategyScannerExitsTableSort',
  IsDividendsStrategyFiltersHidden: 'isDividendsStrategyFiltersHidden',
  DividendsStrategyBuyingPower: 'dividendsStrategyBuyingPower',
  DividendsStrategyNumberOfPositions: 'dividendsStrategyNumberOfPositions',
  DividendsStrategyDataWindowHeightPercent: 'dividendsStrategyDataWindowHeightPercent',
  DividendsStrategyShowEntryIndicator: 'dividendsStrategyShowEntryIndicator',
  DividendsStrategyShowProfitLoss: 'dividendsStrategyShowProfitLoss',
  DividendsStrategyShowTradesOnChart: 'dividendsStrategyShowTradesOnChart',
  ShowPowerXStrategyIndicatorDividendsStrategy: 'showPowerXStrategyIndicatorDividendsStrategy',
  LowestCloseLineIndicatorDividendsStrategy: 'lowestCloseLineIndicatorDividendsStrategy',
  LowestHighestCloseLineIndicatorDividendsStrategy: 'lowestHighestCloseLineIndicatorDividendsStrategy',

  ShortSellingStocksSymbol: 'shortSellingStocksSymbol',
  ShortSellingStocksChartInterval: 'shortSellingStocksChartInterval',
  ShortSellingStocksDataWindowHeightPercent: 'shortSellingStocksDataWindowHeightPercent',
  ShortSellingStocksScannerTab: 'shortSellingStocksScannerTab',
  ShortSellingStocksChartTab: 'shortSellingStocksChartTab',
  ShortSellingStocksScannerNewTableSort: 'shortSellingStocksScannerNewTableSort',
  ShortSellingStocksScannerOpenTableSort: 'shortSellingStocksScannerOpenTableSort',
  ShortSellingStocksScannerAboveEntryTableSort: 'shortSellingStocksScannerAboveEntryTableSort',
  ShortSellingStocksScannerExitTableSort: 'shortSellingStocksScannerExitTableSort',
  ShortSellingStocksWatchlistTableSort: 'shortSellingStocksWatchlistTableSort',
  IsShortSellingStocksScannerHidden: 'isShortSellingStocksScannerHidden',
  ShortSellingStocksScannerFilters: 'shortSellingStocksScannerFilters',
  ShortSellingStocksBuyingPower: 'shortSellingStocksBuyingPower',
  ShortSellingStocksNumberOfPositions: 'shortSellingStocksNumberOfPositions',
  ShowRobIndicatorsShortSellingStocks: 'showRobIndicatorsShortSellingStocks',

  ShortingScannerSymbol: 'shortingScannerSymbol',
  ShortingScannerChartInterval: 'shortingScannerChartInterval',
  ShortingScannerDataWindowHeightPercent: 'shortingScannerDataWindowHeightPercent',
  ShortingScannerScannerTab: 'shortingScannerScannerTab',
  ShortingScannerChartTab: 'shortingScannerChartTab',
  ShortingScannerScannerNewTableSort: 'shortingScannerScannerNewTableSort',
  ShortingScannerScannerOpenTableSort: 'shortingScannerScannerOpenTableSort',
  ShortingScannerScannerAboveEntryTableSort: 'shortingScannerScannerAboveEntryTableSort',
  ShortingScannerScannerExitTableSort: 'shortingScannerScannerExitTableSort',
  ShortingScannerWatchlistTableSort: 'shortingScannerWatchlistTableSort',
  IsShortingScannerFilterHidden: 'isShortingScannerFilterHidden',
  ShortingScannerScannerFilters: 'shortingScannerScannerFilters',
  ShortingScannerBuyingPower: 'shortingScannerBuyingPower',
  ShortingScannerNumberOfPositions: 'shortingScannerNumberOfPositions',
  ShowRobIndicatorsShortingScanner: 'showRobIndicatorsShortingScanner',
  ShowRockyAlways: 'showRockyAlways',
  ShortingScannerFiltersWidth: 'shortingScannerFiltersWidth',
  ShortingScannerDisplayHalts: 'shortingScannerDisplayHalts',

  SearchSymbolSettings: 'searchSymbolSettings',
};

export enum UserSettingsLocal {
  SelectScannerTab = 'selectScannerTab',
  WatchlistSort = 'watchlistSort',
  ScannerResultSort = 'scannerResultSort',
  LoginId = 'loginId',
}

export enum UserSettingsLocalV1 {
  WheelFilter = 'wheelFilter',
  WheelScannerSort = 'wheelScannerSort',
  WheelScannerTab = 'wheelScannerTab',
}

export const StorageKeys = {
  Symbols: 'symbols',
  TradingStrategies: 'tradingStrategies',
  WatchlistData: 'watchlistData',
  PastPerformance: 'pastPerformance',
  PastPerformanceStrategyComparison: 'pastPerformanceStrategyComparison',
  ProcessedData: 'processedData',
  TradeReport: 'tradeReport',
  HistoricalData: 'historicalData',
  SignalDataStrategyComparison: 'signalDataStrategyComparison',
  SecurityDataDetails: 'securityDataDetails',
  Earnings: 'earnings',
  OptionData: 'optionData',
  Splits: 'splits',
  ServerData: 'serverData',
  Exchanges: 'exchanges',
  WheelWatchlistData: 'wheelWatchlistData',
  WtfWatchlistData: 'wtfWatchlistData',
  PowerXWatchlistData: 'powerXWatchlistData',
  DividendsWatchlistData: 'dividendsWatchlistData',
  StockScreenerWatchlistData: 'stockScreenerWatchlistData',
  ShortSellingStocksWatchlistData: 'shortSellingStocksWatchlistData',
  ShortingStocksScannerWatchlistData: 'shortingStocksScannerWatchlistData',
  DividendsStrategyWatchlistData: 'dividendsStrategyWatchlistData',
  Holidays: 'Holidays',
  EarningsCalendar: 'earningsCalendar',
  DividendsCalendar: 'dividendsCalendar',
  ExpectedMove: 'expectedMove',
  IncomeStatement: 'incomeStatement',
  CodyChatBotsList: 'codyChatBotsList',
  Dividends: 'dividends',
  DividendsScanner: 'dividendsScanner',
  DividendsScannerPerformance: 'dividendsScannerPerformance',
  DividendsScannerStatistics: 'dividendsScannerStatistics',
  DividendsStrategy: 'dividendsStrategy',
  DividendsStrategySymbolDetails: 'dividendsStrategySymbolDetails',
  DividendsStrategySymbolDividends: 'dividendsStrategySymbolDividends',
};

export const ServerSettings = {
  ApplicationVersion: 'applicationVersion',
  DataProcessingScreenConfiguration: 'dataProcessingScreenConfiguration',
  PreTradingHubDefaultChecklist: 'preTradingHubDefaultChecklist',
  TradingHubDefaultPlans: 'tradingHubDefaultPlans',
  TradingLogFeelingsData: 'tradingLogFeelingsData',
  HeatmapApiVersion: 'heatmapApiVersion',
  ShowStockScreener: 'showStockScreener',
  ShowImportTrades: 'showImportTrades',
  ShowVideoHub: 'showVideoHub',
  ShowTradierImportTrades: 'showTradierImportTrades',
  ShowRockyDataWindow: 'showRockyDataWindow',
  ShowRockyEconomicCalendar: 'showRockyEconomicCalendar',
  ShowHeatmapDetailsDataWindow: 'showHeatmapDetailsDataWindow',
};

export const Width600 = 600;
export const MobileWidth = 767;
export const TabletMinWidth = 991;
export const DesktopMinWidth = 1200;
export const wheelRightPartMinWidth = 460;
export const wheelRightPartMaxWidthPx = 950;
export const wheelChartSectionDefaultHeight = 50;
export const wheelFilterDefaultHeight = 60;
export const wheelRightPartDefaultWidth = 610;
export const wheelChartSectionMinHeight = 200;
export const wheelCalcSectionMinHeight = 110;
export const wheelDataWindowMinHeight = 55;

export const PinnedOrderPanelMinWidth = 1380;

export const WTF_DATA_WINDOW_DEFAULT_HEIGHT_PERCENT = 50;
export const WTF_DATA_WINDOW_MIN_HEIGHT_PX = 200;

export const STOCK_SCREENER_DETAILS_PANEL_DEFAULT_WIDTH_PERCENT = 50;
export const STOCK_SCREENER_SYMBOL_DETAILS_PANEL_HEIGHT_PX = 250;
export const STOCK_SCREENER_RIGHT_SIDE_MIN_WIDTH_PX = 460;

export const STOCK_SCREENER_LEFT_SIDE_MIN_WIDTH_PX = 480;

export const SHORT_SELLING_STOCKS_DATA_WINDOW_DEFAULT_HEIGHT_PERCENT = 50;
export const SHORT_SELLING_STOCKS_DATA_WINDOW_MIN_HEIGHT_PX = 125;
export const SHORT_SELLING_STOCKS_RIGHT_PART_DEFAULT_WIDTH = 610;
export const SHORT_SELLING_STOCKS_RIGHT_PART_MAX_WIDTH_PX = 950;
export const SHORT_SELLING_STOCKS_RIGHT_PART_MIN_WIDTH = 460;

export const DIVIDENDS_STRATEGY_DATA_WINDOW_DEFAULT_HEIGHT_PERCENT = 50;
export const DIVIDENDS_STRATEGY_DATA_WINDOW_MIN_HEIGHT_PX = 200;

export const TabNames = {
  Home: 'home',
  PowerX: 'powerx',
  Wheel: 'wheel',
  AdminConfiguration: 'admin-configuration',
  MMTrades: 'mm-trades',
  AssetCorrelation: 'asset-correlation',
  TradingLog: 'trading-log',
  EarningsAnalysis: 'earnings-analysis',
  BullCallSpread: 'bull-call-spread',
  LicenseAgreement: 'license-agreement',
  TradingPanel: 'trading-panel',
  StockScreeners: 'stock-screeners',
  ImportTrades: 'import-trades',
  EditionsDemo: 'editions-demo',
  Heatmap: 'heatmap',
  Disclaimer: 'disclaimer',
  Wtf: 'wtf',
  ShortSellingStocks: 'shorting-stocks',
  ShortingScanner: 'shorting-stocks-scanner',
  VideoHub: 'video-hub',

  Calendars: 'calendars', // root calendars page
  MasterCalendar: 'master-calendar',
  DividendsCalendar: 'dividends-calendar',
  EarningsCalendar: 'earnings-calendar',
  HolidaysCalendar: 'holidays-calendar',
  EconomicCalendar: 'economic-calendar',

  DividendsStrategy: 'dividends-strategy',
};

export const Expirations = {
  Minute: 1000 * 60,
  Hour: 1000 * 60 * 60,
};

export enum TradePositions {
  LongAndShort = 'LS',
  LongOnly = 'L',
  ShortOnly = 'S',
}

export enum Countries {
  Canada = 'Canada',
  USA = 'USA',
}

export enum ExchangeCountries {
  US = 'USA',
  CA = 'Canada',
  CC = 'Crypto',
}

export const enum ExchangeCountriesCodes {
  US = 'US',
  CA = 'CA',
  CC = 'CC',
}

export const allExchangeCountryCodes: ReadonlyArray<ExchangeCountriesCodes> = [
  ExchangeCountriesCodes.US,
  ExchangeCountriesCodes.CA,
  ExchangeCountriesCodes.CC,
];

export enum JobStatus {
  New = 'N',
  InProgress = 'P',
  Failed = 'F',
  Done = 'D',
  Overtime = 'O',
}

export const getTradePositionName = (tradePosition): string => {
  if (tradePosition === TradePositions.LongAndShort) {
    return 'Long and Short Positions';
  } else if (tradePosition === TradePositions.LongOnly) {
    return 'Long Positions Only';
  } else if (tradePosition === TradePositions.ShortOnly) {
    return 'Short Positions Only';
  }

  return 'N/A';
};

export const getTradePositionSingularName = (tradePosition): string => {
  if (tradePosition === TradePositions.LongOnly) {
    return 'LONG';
  } else if (tradePosition === TradePositions.ShortOnly) {
    return 'SHORT';
  }

  return 'NONE';
};

export const ChartTabs = {
  Signal: 'signal',
  Chart: 'chart',
  TradingReport: 'tradingReport',
  Options: 'options',
  Dividends: 'dividends',
};

export const getChartTabName = (chartTab): string => {
  if (chartTab === ChartTabs.Signal) {
    return 'Signal';
  } else if (chartTab === ChartTabs.Chart) {
    return 'Chart';
  } else if (chartTab === ChartTabs.TradingReport) {
    return 'Trading Report';
  } else if (chartTab === ChartTabs.Options) {
    return 'Options';
  } else if (chartTab === ChartTabs.Dividends) {
    return 'Dividends';
  }

  return 'N/A';
};

export const chartTabNamesMap = {
  [ChartTabs.Signal]: 'Signal',
  [ChartTabs.Chart]: 'Chart',
  [ChartTabs.TradingReport]: 'Trading Report',
  [ChartTabs.Options]: 'Options',
  [ChartTabs.Dividends]: 'Dividends',
};

export const ShowEntryAndExitOptions = {
  Both: 'Both',
  None: 'None',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getShowEntryAndExitOptionName = (option): any => {
  if (typeof option === 'object' && 'name' in option) {
    return option.name;
  }

  return option;
};

export const ShowTradeOptions = {
  None: 'None',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getShowTradeOptionName = (option): any => {
  if (typeof option === 'object' && 'name' in option) {
    return option.name;
  }

  return option;
};

export const ChartIntervals = {
  Minute: '1',
  Minute5: '5',
  Daily: 'D',
  Daily1: '1D',
  Daily2: '2D',
  Daily3: '3D',
  Weekly: 'W',
  Weekly3: '3W',
  Monthly: 'M',
  Monthly6: '6M',
};

export const getCharResolutionInMinutes = (interval): number => {
  const minutesPerDay = 1440;
  if (interval === ChartIntervals.Daily || interval === ChartIntervals.Daily1) {
    return minutesPerDay;
  } else if (interval === ChartIntervals.Daily3) {
    return minutesPerDay * 3;
  }

  return interval;
};

export const MinStockPriceLevel = 5;

export const getTradeEntryChartObject = (
  trade,
  entryBar,
): { zOrder: string; color: string; price: number; time: number; icon: number } => {
  const isLong = trade.position === TradePositions.LongOnly;
  return {
    zOrder: isLong ? 'top' : 'bottom',
    color: isLong ? '#00FF00' : '#FF0000',
    price: isLong ? entryBar[3] - entryBar[3] * 0.03 : entryBar[2] + entryBar[2] * 0.03,
    time: entryBar[0],
    icon: isLong ? 0xf0d8 : 0xf0d7,
  };
};

export const getTradeExitChartObject = (
  trade,
  exitBar,
): { zOrder: string; color: string; price: number; time: number; icon: number } => {
  const isLong = trade.position === TradePositions.LongOnly;
  return {
    zOrder: 'top',
    color: '#0000FF',
    price: isLong ? exitBar[2] + exitBar[2] * 0.03 : exitBar[3] - exitBar[3] * 0.03,
    time: exitBar[0],
    icon: isLong ? 0xf0d7 : 0xf0d8,
  };
};

export const Colors = {
  Red: '#ff0000',
  Green: '#00ff00',
  Blue: '#0000ff',
  DarkGrey: '#7A7A7A',
  Grey: '#E8EAF0',
  White: '#FFFFFF',
  Yellow: '#FABB00',
  BondiBlue: '#0387B0',
  ForestGreen: '#2CBF36',
  Pink: '#F597B9',
  VolumeLightGreen: '#90D5C9',
  VolumeLightRed: '#FBA8AF',
};

export const earningsAboveExpectationsMarkerColor = 'rgb(8, 153, 129)';
export const earningsBelowExpectationsMarkerColor = 'rgb(242, 54, 69)';
export const earningsMarkerColor = 'rgba(216, 0, 251, 1)'; // #D800FB

export const dividendsMarkerColor = 'rgba(28, 69, 255, 1)'; // #1C45FF

export const splitMarkerColor = 'rgba(235, 135, 5, 1)'; // #EB8705
export const upcomingSplitMarkerColor = 'rgba(242, 54, 69)'; // #EB8705
export const futureSplitMarkerColor = 'rgba(216, 0, 251, 1)'; // #D800FB

export const shortingStocksCandidateStateMarkerColor = 'rgb(3, 146, 206)';
export const haltMarkerColor = 'rgb(246, 116, 2)';

export const expectedMove1LineColor = 'rgba(33, 150, 243, 1)'; // #2196F3
export const expectedMove2LineColor = 'rgba(28, 113, 181, 1)'; // #1C71B5

export enum ColorClasses {
  Red = 'red',
  Green = 'green',
}

// TODO: move to utils, temp solution for SOL data-window position component (2-4 fraction digits)
export const formatPriceV2 = (
  price: number,
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = undefined,
): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(price);
};

export const formatPrice = (price: number, profitScale: number = 2): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: profitScale,
  }).format(price);
};

export const formatNumber = (value): string => {
  return new Intl.NumberFormat('en-US').format(value);
};

export const formatDecimal = (value, numDigits = 2): string => {
  return formatDecimalExt(value, numDigits, numDigits);
};

export const formatPercentage = (value: number, maximumFractionDigits = 0): string => {
  return new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits }).format(value / 100);
};

export const formatDecimalExt = (value: number, minDigits = 2, maxDigits = 2): string => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  }).format(value);
};

export const hasValue = (value?: unknown): boolean => {
  return !(value === null || value === undefined);
};

export const formatPriceOrZero = (value: number | null, minDigits = 2): string => {
  return value === 0 || !value || isNaN(value) ? '0' : formatPrice(value, minDigits);
};

export const formatPriceOrEmptyOrZero = (value: number | null): string => {
  return value === 0 ? '0' : !value || isNaN(value) ? '-' : formatPrice(value, 2);
};

export const formatIntOrZero = (value: number | null): string => {
  return value === 0 || !value || isNaN(value) ? '0' : formatNumber(value);
};

export const formatDecimalOrZero = (value: number | null, minDigits = 2, maxDigits = 2): string => {
  return value === 0 || !value || isNaN(value) ? '0' : formatDecimalExt(value, minDigits, maxDigits);
};

export const isNumberHasValue = (value: number | null | undefined): boolean => value !== null && value !== undefined;

export const isNullOrUndefined = (value: unknown): boolean => value === null || value === undefined;

export const round = (num: number, numDecimals: number): number => {
  let multiplier = null;
  if (numDecimals === 1) {
    multiplier = 10;
  } else if (numDecimals === 2) {
    multiplier = 100;
  } else if (numDecimals === 4) {
    multiplier = 10000;
  } else if (numDecimals === 6) {
    multiplier = 1000000;
  } else if (numDecimals === 8) {
    multiplier = 100000000;
  }

  if (!multiplier) {
    throw new Error(`${numDecimals} is not supported`);
  }

  return Math.round(num * multiplier) / multiplier;
};

// TODO: move to utils
export const sanitizeNumberString = (str: string): number | null => {
  if (!str?.length) {
    return null;
  }

  const sanitizedStr = str.replace(/[^0-9.-]+/g, '');
  const num = Number(sanitizedStr);
  return isNaN(num) ? null : num;
};

export const formatStrike = (strike: number): string => {
  const fullPrice = formatPrice(strike, 2).replace(/0+$/, '');
  return fullPrice[fullPrice.length - 1] === '.' ? fullPrice.slice(0, fullPrice.length - 1) : fullPrice;
};

export enum Signals {
  BTO = 'BTO',
  BTC = 'BTC',
  STO = 'STO',
  STC = 'STC',
}

export enum OptionValueType {
  ITM = 'itm',
  ATM = 'atm',
}

export enum Keys {
  UP_ARROW = 'ArrowUp',
  DOWN_ARROW = 'ArrowDown',
  DELETE = 'Delete',
  BACKSPACE = 'Backspace',
}

export enum Positions {
  Long = 'L',
  Short = 'S',
}

export const getSharesCount = (accountRiskAmount, riskPerShare): number =>
  riskPerShare > 0 ? Math.floor(accountRiskAmount / riskPerShare) : 0;

export const getSharesCountByBuyingPower = (
  buyingPower: number,
  numberOfPositions: number,
  stopLossPrice: number,
): number => {
  if (!numberOfPositions || !stopLossPrice) {
    return 0;
  }

  return Math.trunc(buyingPower / numberOfPositions / stopLossPrice);
};

export const getDecimalSharesCount = (accountRiskAmount: number, riskPerShare: number): number => {
  return riskPerShare > 0 ? Math.round((accountRiskAmount / riskPerShare) * 1000000) / 1000000 : 0;
};

export const getContractsCount = (accountRiskAmount: number, riskPerShare: number): number =>
  riskPerShare > 0 ? Math.trunc(accountRiskAmount / (riskPerShare * 100)) : 0;

export const daysNumberForRoiCalculations = 360;

export enum Themes {
  Dark = 'dark',
  Light = 'light',
}

export enum ThemesNumValues {
  Dark = 0,
  Light = 1,
}

export const EasternTimeZoneName = 'America/New_York';
export const defaultSymbolName = 'AAPL';

export const getRegExpWithSpecialCharacters = (str: string): string => {
  return str.replace(/[\\]+/, '').replace(/[.|*+?{}[]^\$]/, (replaced) => '\\' + replaced);
};

export const notEnoughDataText =
  'The symbol has been trading for less than 2 years. Therefore, we only have limited data and we recommend not to trade it.';

export const getPastPerformancePropertyName = (propertyNameBase: string, tradePosition: TradePositions): string => {
  switch (tradePosition) {
    case TradePositions.ShortOnly:
      return propertyNameBase + '_short';
    case TradePositions.LongOnly:
      return propertyNameBase + '_long';
    default:
      return propertyNameBase;
  }
};

export const TechnicalIndicators = {
  None: 'None',
  Show: 'Show',
  ShowValues: 'Show with values',
};
export const getValueFromLocalStorage: <T = unknown>(key: string) => T | undefined = (key) =>
  JSON.parse(localStorage.getItem(key));

export const BannerCookieExpirationDays = 90;

export const SaveChartDebounceDelay = 2100;

export enum OrderType {
  Buy = 'BUY',
  Sell = 'SELL',
}

export enum OrderDuration {
  Day = 'Day',
  GTC = 'Good-Til-Cancel (GTC)',
}

export const CookieExpireTimeInMinutes = {
  15: 0.02,
  30: 0.04,
};

export enum OrderStatus {
  expired = 'expired',
  canceled = 'canceled',
  rejected = 'rejected',
  error = 'error',
  open = 'open',
  partially_filled = 'partially_filled',
  filled = 'filled',
  pending = 'pending',
}

export const DefaultPrintOptions = ['Charts', 'Data Window', 'Trading Report', 'Options'];

export enum PrintOptions {
  Chart = 'Charts',
  DataWindow = 'Data Window',
  TradingReport = 'Trading Report',
  Options = 'Options',
  TradingPlan = 'Trading Plan',
}

export enum ChartSaveData {
  PowerX = 'powerXChartData',
  Wheel = 'wheelChartData',
  BCS = 'bcsChartData',
  Wtf = 'wtfChartData',
  Dividends = 'dividendsChartData',
  DividendsStrategy = 'dividendsStrategyChartData',
  StockScreener = 'stockScreenerChartData',
  ShortSellingStocks = 'shortSellingStocksChartData',
  ShortingScanner = 'shortingScannerChartData',
}

export const staticStudies = [
  'WTF',
  'PowerX',
  'ProfitLoss',
  'FirstProfitLoss',
  'SecondProfitLoss',
  'StochasticValueIndicator',
  'StochasticIndicator',
  'RsiValueIndicator',
  'RsiIndicator',
  'MacdSignalIndicator',
  'MacdValueIndicator',
  'MacdIndicator',
  'StochasticIndicatorWithName',
  'RsiIndicatorWithName',
  'MacdIndicatorWithName',
  'Lowest Close Line indicator',
  "Jan's indicator",
  'Compare',
  'BarColoring',
  'Monthly Pivot Points',
  chartStudies.PrePostMarketBackgroundColoring,
];

export const studiesWithForceOverlay = ['Volume'];

export const enum chartStudies {
  Volume = 'Volume',
  PowerXStrategy = 'PowerX',
  PrePostMarketBackgroundColoring = 'PrePostMarketBackgroundColoring',
}

export const enum chartPaneTypes {
  MainPaneWithGraphic = 'MainSeries',
}

export enum RiskType {
  Conservative = 'conservative',
  Aggressive = 'aggressive',
  All = 'all',
}

export const loginUrl = '/login';
export const homeUrl = '/home';
export const theWheelUrl = '/wheel';
export const powerXUrl = '/powerx';
export const userAgreementUrl = '/user-agreement';
export const tradingLogUrl = '/trading-log';
export const tradingPanelUrl = '/trading-panel';
export const stockScreenerUrl = '/stock-screeners';
export const earningsAnalysisUrl = '/earnings-analysis';
export const heatmapUrl = '/heatmap';
export const disclaimerUrl = '/disclaimer';
export const licenseAgreementUrl = '/license-agreement';
export const bullCallSpreadUrl = '/bull-call-spread';
export const adminConfigurationUrl = '/admin-configuration';
export const wtfUrl = '/wtf';
export const mmTradesUrl = '/mm-trades';
export const assetCorrelationUrl = '/asset-correlation';
export const importTradesUrl = '/import-trades';
export const shortSellingStocksUrl = '/shorting-stocks';
export const videoHubUrl = '/video-hub';
export const shortingStocksScannerUrl = '/shorting-stocks-scanner';
export const dividendsStrategyUrl = 'dividends-strategy';

// CALENDARS
export const calendarRootUrl = '/calendars';
export const masterCalendarUrl = '/calendars/master';
export const economicCalendarUrl = '/calendars/economic';
export const dividendsCalendarUrl = '/calendars/dividends';
export const earningsCalendarUrl = '/calendars/earnings';
export const holidaysCalendarUrl = '/calendars/holidays';

// important: keep DefaultInitialTab and DefaultInitialPageUrl synced
export const DefaultInitialTab = TabNames.Home;
export const DefaultInitialPageUrl = homeUrl;

export const cookiesToBeClearedOnLogoutList: ReadonlyArray<string> = ['brokerageToken', 'tradierAccount'];

export const localStorageKeysTobeClearedOnRefresh: ReadonlyArray<string> = [];
export const localStorageKeysToKeepOnLogout: ReadonlyArray<string> = ['PS_LAST_SEEN', 'v2/chat-history'];

export const MMTradesTableUrl =
  'https://docs.google.com/spreadsheets/d/17lftxFnjCqM6iZAJi34M06yWFHyjJeJrDBHi04rOR_Y/edit?usp=sharing';

export enum USExchangesForGoogleSearch {
  N = 'NYSE',
  Q = 'NASDAQ',
  A = 'NYSEAMERICAN',
  P = 'NYSEARCA',
  Z = 'BATS',
}

export enum CAExchangesForGoogleSearch {
  V = 'CVE',
  TO = 'TSE',
  CN = 'CNSX',
}

export enum WatchlistType {
  PowerX = 'P',
  Wheel = 'W',
  Wtf = 'F',
  Dividends = 'D',
  StockScreener = 'S',
  ShortSellingStocks = 'H',
  ShortingStocksScanner = 'Q',
  DividendsStrategy = 'G',
}

export const enum WheelData {
  FiltersContainerWidth = 'wheelFiltersWidth',
}

export const tradeReportMaxWidthForPrint = 950;

export const saveTabStateDebounceTime = 500;
export const saveViewModeDebounceTime = 200;
export const saveTableDebounceTime = 200;
export const changeCalcSymbolDebounceTime = 700;
export const selectWheelScannerSymbolDebounceTime = 200;
export const saveBullCallSpreadSettingsDebounceTime = 500;

export const saveTradingHubSettingsDebounceTime = 3000;

export const bcsRightPartMinWidth = 500;
export const bcsRightPartDefaultWidth = 650;

export const daysNumberToCheckInTimer = 21;
export const maxErrorReportSizeInKb = 200;
export const earningsCalendarFilterDebounceTime = 1000;

export const maxRecentSymbolsLength = 10;

export enum ChangePasswordLabels {
  currentPassword = 'Current password',
  newPassword = 'New password',
  confirmNewPassword = 'Reenter new password',
}

export const enum CurrentPasswordErrors {
  DoesNotMatch = "Current password doesn't match with record",
  IsInvalid = 'Current password is invalid',
  Required = 'Enter your current password',
}

export const enum MomentDateTimeFormats {
  ReadableDate = 'MMM D, YY',
  ReadableDateFullYear = 'MMM D, YYYY',
  ReadableDateShortYearWithTime = 'MMM D, YY HH:mm',
  ReadableNoYearDate = 'MMM D',
  ReadableNoYearWeekDate = 'ddd, MMM D',
  ReadableNoDayDate = 'MMM YY',
  InputDateDayMonthFullYear = 'DD/MM/YYYY',
  InputFullYearDate = 'MM/DD/YYYY',
  InputDate = 'MM/DD/YY',
  InputDateFullYearMonthDay = 'YYYY/MM/DD',
  InputDateFullYearDayMonth = 'YYYY/DD/MM',
  NoYearDate = 'MM/DD',
  NoYearDateDaysFirst = 'DD/MM',
  ServerDate = 'YYYY-MM-DD',
  ReadableTime = 'HH:mm',
  InputTime = 'H:m',
  FullTime = 'HH:mm:ss',
  FullTimeAmPm = 'h:mm:ss A',
  TimeAmPm = 'h:mm A',
  ReadableFullTimeWithYear = 'YYYY-MM-DD HH:mm:ss',
  FullYear = 'YYYY',
  Iso8601WithoutTimeZone = 'YYYY-MM-DDTHH:mm:ss',
  ReadableWeekDayWithDateAndTime = 'ddd, MMM D h:mm A',
}

export const enum TopMenuItems {
  MasterCalendar = 'CALENDARS',
  AssetCorrelation = 'ASSET CORRELATION',
  MMTrades = 'M & M TRADES',
  TradingLog = 'TRADING LOG',
  TradingPanel = 'TRADING PANEL',
  StockScreener = 'STOCK SCREENERS',
  EarningsAnalysis = 'EARNINGS ANALYSIS',
  Heatmap = 'HEATMAP',
  ShortSellingStocks = 'SHORTING STOCKS',
  ShortingStocksScanner = 'SHORTING SCANNER',
  VideoHub = 'VIDEO HUB',
}

export const rightsToEditMMTrades = 'editMMTrades';

export const WheelROIDefaultRange = {
  Lower: 30,
  Upper: 40,
};
export const searchFieldDebounceTimeMS = 600;

export const socketTimeoutMs = 10 * 1000; //10 sec.
export const TLSendCommandTimeout = 55 * 1000; //55 sec.

export const tradingLogDefaultTransactionType = TradingLogTransactionType.SellPut;

export const tradingHubModalID = 'trading-hub-modal';

export const enum LowestHighestCloseLineIndicatorOptions {
  Both = 'Both',
  LowestLine = 'Lowest Line',
  HighestLine = 'Highest Line',
  None = 'None',
}

export const enum LowestCloseLineIndicatorOptions {
  LowestLine = 'Lowest Line',
  None = 'None',
}

export const enum PowerXStrategyIndicatorOptions {
  Show = 'Show',
  None = 'None',
}

export const enum WtfShowTradesOptions {
  Show = 'Show',
  None = 'None',
}

export const enum WtfShowProfilLossOptions {
  Show = 'Show',
  None = 'None',
}

export const enum StockScreenerShowTradesOptions {
  Show = 'Show',
  None = 'None',
}

export const enum EarningsTimeOfDay {
  BeforeMarket = 'BeforeMarket',
  DuringMarket = 'DuringMarket',
  AfterMarket = 'AfterMarket',
}

export const tradingLogMinAllowedDate = moment('2000-01-01', MomentDateTimeFormats.ServerDate);

export const PxoOrderTag = 'pxo';

export const enum Broker {
  Tradier = 'TRDR',
}

export const enum TradingCommandType {
  GetUserProfile = 'get_user_profile',
  GetPositions = 'get_positions',
  GetOrders = 'get_orders',
  GetHistory = 'get_history',
  UpdateAccountNickname = 'update_account_nickname',
}

export enum UserAccessType {
  Admin = 0,
  Normal = 1,
  Support = 2,
}

export enum UserAccessLevels {
  Basic = 'Basic',
  Silver = 'Silver',
  Max = 'Max',
  Mastermind = 'Mastermind',
}

// feature can be any element in the app: section, hint, chart indicator, tab/page, columns in table, etc ...
// important: there are only elements with access restrictions, described in EditionsService and back-end settings
// important: features do not represent all tabs/pages (and should not do this) - use TabNames for pages and navigation
export enum Features {
  MasterCalendar = 'master-calendar',
  DividendsCalendar = 'dividends-calendar',
  EarningsCalendar = 'earnings-calendar',
  HolidaysCalendar = 'holidays-calendar',
  EconomicCalendar = 'economic-calendar',
  PowerX = 'power-x',
  MMTrades = 'mm-trades',
  BullCallSpread = 'bull-call-spread',
  PxoCommunity = 'pxo-community',
  EarningsAnalysis = 'earnings-analysis',
  AssetCorrelation = 'asset-correlation',
  TradingHub = 'trading-hub',
  TradingLog = 'trading-log',
  ExpectedMove = 'expected-move',
  Wheel = 'wheel',
  WheelCalculatorLiveData = 'wheel-calculator-live-data',
  TradingPanel = 'trading-panel',
  StockScreeners = 'stock-screeners',
  ImportTrades = 'import-trades',
  Heatmap = 'heatmap',
  IBot = 'i-bot',
  Disclaimer = 'disclaimer',
  LowHighIndicator = 'low-high-indicator',
  SmileyStatistics = 'smiley-statistics',
  Wtf = 'wtf',
  ShortSellingStocks = 'short-selling-stocks',
  VideoHub = 'video-hub',
  ShortingStocksScanner = 'shorting-stocks-scanner',
  DividendsStrategy = 'dividends-strategy',
}

export const pluginNames = {
  ShortingStocks: 'shortingStocks',
  Wtf: 'wtf',
};

export enum TradingLogInputDateFormats {
  MonthDayYear = 'MM/DD/YY',
  DayMonthYear = 'DD/MM/YY',
  FullYearMonthDay = 'YYYY/MM/DD',
  FullYearDayMonth = 'YYYY/DD/MM',
}

export const defaultTradingLogInputDateFormats = TradingLogInputDateFormats.MonthDayYear;

export enum TradingHubTabs {
  Checklist = '0',
  TradingPlan = '1',
  MagicBall = '2',
  IBot = '3',
  IBot2 = '4',
  IBot3 = '5',
}

export enum DataChannelCommands {
  Sp500Heatmap = 'sp500_heatmap',
  DowJonesHeatmap = 'dow_jones_heatmap',
  NasdaqHeatmap = 'nasdaq_heatmap',
  CompositeIndexHeatmap = 'composite_index_heatmap',
  CompositeIndexHeatmap2 = 'composite_index_heatmap_2',
  UsLiveData = 'us_live_data',
  PxoSmileySubscribe = 'pxo_smiley_subscribe',
  WheelSmileySubscribe = 'wheel_smiley_subscribe',
  WtfSmileySubscribe = 'wtf_smiley_subscribe',
  StockScreenerSmileySubscribe = 'symbol_screener_smiley_subscribe',
  ShortSellingStocksSmileySubscribe = 'short_selling_stocks_smiley_subscribe',
  ShortingStocksScannerSmileySubscribe = 'shorting_stocks_scanner_smiley_subscribe',
  DividendsStrategySmileySubscribe = 'dividends_smiley_subscribe',
  PxoSmileyExecute = 'pxo_smiley_execute',
  WheelSmileyExecute = 'wheel_smiley_execute',
  WtfSmileyExecute = 'wtf_smiley_execute',
  StockScreenerSmileyExecute = 'symbol_screener_smiley_execute',
  ShortSellingStocksSmileyExecute = 'short_selling_stocks_smiley_execute',
  ShortingStocksScannerSmileyExecute = 'shorting_stocks_scanner_smiley_execute',
  DividendsStrategySmileyExecute = 'dividends_smiley_execute',
  TimeSales = 'time_sales',
  StockScreener = 'symbol_screener',
  ShortSellingStocks = 'short_selling_stocks',
  MMTrades = 'mm_trades_subscribe',
  MMTradesExecute = 'mm_trades_execute',
  ShortingStocksScanner = 'short_selling_stocks_scanner',
  WebsitePopupNotificationsSubscribe = 'website_popup_notifications_subscribe',
  HaltsSubscribe = 'halts_subscribe',
  WtfSubscribe = 'wtf_subscribe',
}

export const DEFAULT_UPDATE_FLAGS_DELAY_MS = 200;

export const DefaultOptionsSymbolsTableSortState = { column: 'symbol', direction: 'asc' as SortDirection };

export const DefaultStocksSymbolsTableSortState = { column: 'symbol', direction: 'asc' as SortDirection };

export enum TradingAccountCalculationMethods {
  Average = 'A',
  Fifo = 'F',
  Lifo = 'L',
}

export const TradingLogNotDefinedAccountId = '00000000-0000-0000-0000-000000000000';

export enum ScannerResultTypes {
  Pxo = 'P',
  Wtf = 'F',
}

export const TradingLogTransactionIsOptionMap: Record<TradingLogTransactionType, boolean> = {
  BS: false,
  SS: false,
  BC: true,
  SC: true,
  BP: true,
  SP: true,
  DI: false,
};

export const TradingLogTransactionIsStockMap: Record<TradingLogTransactionType, boolean> = {
  BS: true,
  SS: true,
  BC: false,
  SC: false,
  BP: false,
  SP: false,
  DI: false,
};

export const TLDashboardMetricDetailsModalPageSizeOptions = [25, 50, 100];

export const QuoteOfDayImagesCount = 102;

export const QuoteOfDayDisplayImagesCount = 1;

export const tlAccountStrategyMaximumColorCount = 30;
export const tlAccountStrategyDefaultColorNumber = 1;

export const TL_EXPORT_PROGRESS_SHOW_MIN_TIME_MS = 1000;

export const TLImportTimeoutMs = 30 * 60 * 1000; // 30 min

export enum TimeSaleIntervals {
  Min1 = '1min',
  Min5 = '5min',
  Min15 = '15min',
}

export const SELECT_SCANNER_SYMBOL_DEBOUNCE_TIME = 500;

export const TLImportTradesMaxFileSizeBytes = 1024 * 1024 * 10; // 10Mb;
export const TLImportTradesFileAllowedExt = ['.csv', '.xls', '.xlsx'];

export enum RockyPromts {
  EconomicCalendarDefault = 'Explain the economic calendar events in the following ways:<br>' +
    "1. Explain why the <b>'{eventName}'</b> is important;<br>" +
    "2. Explain the impact of the next release of the <b>'{eventName}'</b> on the overall stock market;<br>" +
    "3. Explain the impact of the next release of the <b>'{eventName}'</b> on various sectors of the stock market;",
  EconomicCalendarFourthLine = "4. Explain <b>'{eventName}'</b> values: ",
  DataWindow = 'Act as a stock and options trading specialist. Explain the following stock terms to a beginner.<br>' +
    'When explaining it, look at the value of the term and explain it in relation to the overall market and typical value for stocks in the S&P 500 and Nasdaq 100 index.<br>' +
    'Explain metrics and what they measure:<br>',
}

export const NumberUnits: NumberUnitModel[] = [
  { key: 'Q', value: 1e15 },
  { key: 'T', value: 1e12 },
  { key: 'B', value: 1e9 },
  { key: 'M', value: 1e6 },
  { key: 'K', value: 1e3 },
];

export const DEFAULT_HEATMAP_FILTERS: IHeatmapFilters = {
  sector: null,
  industry: null,
};

export const VideoHubApiKeys: Record<VideoHubApiKeysType, string> = environment.VideoHubConfiguration.apiKeys;

export const VideoHubWordPressUrls: Record<VideoHubWordPressUrlsType, string> = {
  tagById:
    environment.VideoHubConfiguration.wordPressDomainName +
    'pxo-video-tag/${id}?acf_format=standard&_fields=id,name' +
    '&user-edition=${userEdition}',
  videosByTag:
    environment.VideoHubConfiguration.wordPressDomainName +
    'pxo-video?acf_format=standard' +
    '&_fields=id,title,acf.video_url,acf.video_description,acf.video_thumbnail.url,acf.video_duration,' +
    'acf.video_posted,acf.category.term_id,acf.category.name,acf.category,acf.tags,acf.devs_only' +
    '&pxo-video-tag=${tagId}&orderby=menu_order&order=asc&per_page=${per_page}&nopaging=${nopaging}&user-edition=${userEdition}',
  categories:
    environment.VideoHubConfiguration.wordPressDomainName +
    'pxo-video-category?_fields=id,description,name,count,slug,parent&per_page=${per_page}&parent=${parent}&nopaging=${nopaging}&user-edition=${userEdition}',
  categoryById:
    environment.VideoHubConfiguration.wordPressDomainName +
    'pxo-video-category/${id}?_fields=id,description,name,count,parent&user-edition=${userEdition}',
  videoById:
    environment.VideoHubConfiguration.wordPressDomainName +
    'pxo-video/${id}/?acf_format=standard' +
    '&_fields=id,title,acf.video_url,acf.video_description,acf.video_thumbnail.url,acf.video_duration,acf.video_posted,' +
    'acf.category.term_id,acf.category.name,acf.category,acf.tags,acf.devs_only&orderby=menu_order&order=asc' +
    '&user-edition=${userEdition}',
  allVideos:
    environment.VideoHubConfiguration.wordPressDomainName +
    'pxo-video?acf_format=standard' +
    '&_fields=id,title,acf.video_url,acf.video_description,acf.video_thumbnail.url,acf.video_duration,acf.video_posted,' +
    'acf.category.term_id,acf.category.name,acf.category,acf.tags,acf.devs_only&orderby=${orderby}&order=${order}' +
    '&per_page=${per_page}&nopaging=${nopaging}&ignore-child=${ignore-child}&user-edition=${userEdition}',
};

export const DefaultRockyAlwaysVisible = true;

export const DefaultSymbolSearchSettingsRecord: SymbolSearchSettingsRecord = {};
export const DefaultSymbolSearchSettingsValue: boolean = false;
