import { Component, computed, ElementRef, Inject, Injector, OnInit, Signal, signal, ViewChild } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DefaultSymbolSearchSettingsValue,
  ExchangeCountries,
  getRegExpWithSpecialCharacters,
  MobileWidth,
  Themes,
  UserSettings,
  WatchlistType,
} from '@const';
import { DEFAULT_SEARCH_BAR_SETTINGS } from '@constants/search-bar.constants';
import { ISearchDialogData, ISearchSymbol } from '@mod/search-bar/search-bar.model';
import { ObservableService } from '@s/observable.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { UserDataService } from '@s/user-data.service';
import { IWatchlistData, WatchlistDataService } from '@s/watchlist-data.service';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  from,
  fromEvent,
  map,
  merge,
  Observable,
  scan,
  startWith,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs';

@Component({
  selector: 'app-symbol-search-modal',
  templateUrl: './symbol-search-modal.component.html',
  styleUrls: ['./symbol-search-modal.component.scss'],
})
export class SymbolSearchModalComponent implements OnInit {
  protected searchForm: FormGroup;
  protected watchlistData: Signal<IWatchlistData[]>;
  protected allSymbols: Observable<ISearchSymbol[]>;
  protected isPowerx: boolean;
  protected findedSymbols: Signal<ISearchSymbol[]>;
  protected exchangeCountries = ExchangeCountries;
  protected modalTitle = computed(() => {
    const data = this.dialogData();

    return data.customHeader
      ? data.customHeader
      : data.showAddWatchlistButton
        ? 'Add Symbol to Watchlist'
        : 'Symbol Search';
  });

  protected showAddToWatchListButton = computed(() => this.dialogData()?.showAddWatchlistButton);
  protected addToWatchlistSvg = this.observableService.theme.pipe(
    map((theme) => `../../../../../assets/img/add-s${theme === Themes.Dark ? '-white' : ''}.svg`),
  );
  @ViewChild('myinput')
  protected searchInputField: ElementRef;
  protected isMobile = toSignal(
    fromEvent(window, 'resize').pipe(
      startWith(null),
      debounceTime(100),
      distinctUntilChanged(),
      map(() => window.innerWidth < MobileWidth),
    ),
    { injector: this.injector },
  );
  private onWatchListUpdate = new Subject<ISearchSymbol>();
  private dialogData = signal<ISearchDialogData>(DEFAULT_SEARCH_BAR_SETTINGS);
  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) private searchDialogData: ISearchDialogData,
    private dialogRef: MatDialogRef<SymbolSearchModalComponent>,
    private observableService: ObservableService,
    private watchlistDataService: WatchlistDataService,
    private symbolsService: SymbolsService,
    private userDataService: UserDataService,
    private fb: FormBuilder,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    this.dialogData.update((dialogData) => ({ ...dialogData, ...this.searchDialogData }));
    this.searchForm = this.fb.group({
      searchValue: [this.dialogData().searchString, [Validators.required, Validators.maxLength(100)]],
      weeklyOptions: [DefaultSymbolSearchSettingsValue],
    });
    this.isPowerx = this.dialogData().isPowerX;

    this.subscription.add(
      combineLatest([this.observableService.symbolSearchSettings, this.observableService.activeTab])
        .pipe(
          map(([settings, activeTab]) =>
            settings[activeTab] ? settings[activeTab].isOnlyWeeklyOptions : DefaultSymbolSearchSettingsValue,
          ),
          distinctUntilChanged(),
        )
        .subscribe((isOnlyWeeklyOptions) => this.searchForm.patchValue({ weeklyOptions: isOnlyWeeklyOptions })),
    );

    this.watchlistData = toSignal(
      merge(
        this.observableService.watchlistUpdated.pipe(
          startWith(null),
          switchMap(() => from(this.watchlistDataService.get(WatchlistType.PowerX))),
        ),
        this.onWatchListUpdate,
      ).pipe(
        scan((acc: IWatchlistData[], value: IWatchlistData[] | ISearchSymbol) => {
          if (!value) {
            return acc;
          }

          if (Array.isArray(value)) {
            return [...value];
          }

          const isItemExist = acc.some((item) => item.security_id === value.security_id);

          if (isItemExist) {
            return acc.filter((item) => item.security_id !== value.security_id);
          }

          return [
            ...acc,
            {
              security_id: value.security_id,
            } as IWatchlistData,
          ];
        }, []),
      ),
      { injector: this.injector },
    );

    this.allSymbols = combineLatest([
      toObservable(this.watchlistData, { injector: this.injector }),
      from(this.symbolsService.getAll()),
    ]).pipe(
      map(([watchlist, symbols]) => {
        return symbols
          .map((symbol) => ({
            ...symbol,
            isInWatchlist: watchlist?.some((item) => item.security_id === symbol.security_id),
          }))
          .sort((a, b) => a.symbol.localeCompare(b.symbol)) as ISearchSymbol[];
      }),
    );

    this.subscription.add(
      this.searchForm.controls['weeklyOptions'].valueChanges
        .pipe(debounceTime(100), distinctUntilChanged())
        .subscribe(async (weeklyOptions) => {
          const currentRecordValue = this.observableService.symbolSearchSettings.value;
          const currentTab = this.observableService.activeTab.value;

          const updatedData = {
            ...currentRecordValue,
            [currentTab]: { tab: currentTab, isOnlyWeeklyOptions: weeklyOptions },
          };

          this.observableService.symbolSearchSettings.next(updatedData);
          await this.userDataService.set(UserSettings.SearchSymbolSettings, updatedData);
        }),
    );

    this.findedSymbols = toSignal(
      combineLatest([this.searchForm.valueChanges.pipe(startWith(this.searchForm.value)), this.allSymbols]).pipe(
        distinctUntilChanged(),
        map(([formValue, allSymbols]) => {
          const { searchValue = '' } = formValue;
          const searchValueLength = searchValue.length;

          if (!searchValueLength) {
            return [...allSymbols];
          }

          const regExpStr = getRegExpWithSpecialCharacters(searchValue);
          const symbolSearchRegex = new RegExp(`^${regExpStr}`, 'i');
          const descriptionSearchRegex = new RegExp(regExpStr, 'i');

          const matchesSearchCriteria = (symbolObj: ISymbol): boolean =>
            searchValueLength === 1
              ? symbolSearchRegex.test(symbolObj.symbol)
              : symbolSearchRegex.test(symbolObj.symbol) || descriptionSearchRegex.test(symbolObj.description || '');

          const sortMatches = (a: ISymbol, b: ISymbol): number => {
            const aMatches = symbolSearchRegex.test(a.symbol);
            const bMatches = symbolSearchRegex.test(b.symbol);
            return aMatches === bMatches ? 0 : aMatches ? -1 : 1;
          };

          return [...allSymbols].filter(matchesSearchCriteria).sort(sortMatches);
        }),
        map((symbols) => {
          const { weeklyOptions } = this.searchForm.value;

          return symbols.filter((symbol) => (weeklyOptions ? !!symbol.has_weekly_options : true));
        }),
        map((symbols) => {
          const filterByCountrySource = (symbol: ISymbol): boolean =>
            !!this.dialogData().symbolSources.find((source) => source === symbol.country_code);

          const sortByCountryCode = (a: ISymbol, b: ISymbol): number => {
            if (a.symbol === b.symbol) {
              return a.country_code > b.country_code ? -1 : a.country_code < b.country_code ? 1 : 0;
            }
          };

          return symbols.filter(filterByCountrySource).sort(sortByCountryCode);
        }),
        map((findedSymbols) => findedSymbols.slice(0, 40)),
      ),
      { injector: this.injector },
    );

    setTimeout(() => {
      if (this.dialogData().selectInput) {
        this.searchInputField.nativeElement.select();
      }
    });
  }

  ngAfterViewChecked(): void {
    setTimeout(() => {
      this.searchInputField.nativeElement.focus();
    }, 0);
  }

  protected clearSearchResult(): void {
    this.searchForm.patchValue({
      searchValue: '',
    });
    this.searchInputField.nativeElement.focus();
  }

  protected onEnterButton(): void {
    if (this.findedSymbols() && this.findedSymbols().length) {
      this.selectSymbol(this.findedSymbols()[0]);
    }
  }

  protected selectSymbol(symbol: ISymbol): void {
    const dialogData = this.dialogData();

    if (dialogData.showAddWatchlistButton) {
      return;
    }

    this.handleSymbolSelection(symbol, dialogData);

    if (dialogData.clearSearchStringAfterSelection) {
      this.clearSearchResult();
    }
  }

  private handleSymbolSelection(symbol: ISymbol, dialogData: ISearchDialogData): void {
    if (dialogData.callbackInsteadOfClose) {
      dialogData.callbackInsteadOfClose(symbol);
      setTimeout(() => {
        this.updatePosition();
      }, 100);
    } else {
      this.dialogRef.close(symbol);
    }
  }

  private updatePosition(): void {
    const dialogData = this.dialogData();

    if (dialogData.stickTo) {
      this.dialogRef.updatePosition({
        top: `${
          dialogData.stickTo.offsetTop +
          dialogData.stickTo.offsetHeight -
          (dialogData.scrollElement ? dialogData.scrollElement.scrollTop : 0) +
          5
        }px`,
        left: `${dialogData.stickTo.offsetLeft}px`,
      });
    }
  }

  protected async addToWatchlist(symbol: ISearchSymbol): Promise<void> {
    this.onWatchListUpdate.next(symbol);
    await this.watchlistDataService.insert(symbol.security_id, WatchlistType.PowerX);
    this.observableService.watchlistUpdated.next(true);
  }

  protected async deleteFromWatchlist(symbol: ISymbol): Promise<void> {
    const id = this.watchlistData().find((el) => el.security_id === symbol.security_id)?.id;
    if (id) {
      this.onWatchListUpdate.next(symbol);
      await this.watchlistDataService.remove(id);
      this.observableService.watchlistUpdated.next(true);
    }
  }
}
